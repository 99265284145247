.card {
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 1rem;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2)); /* Initial shadow */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  padding: 0.75rem;
}

.card:hover {
  filter: drop-shadow(
    0px 4px 8px rgba(0, 0, 0, 0.3)
  ); /* Larger shadow on hover */
  transform: translateY(-4px); /* Slight lift effect */
}

.card-image {
  border-radius: 1rem;
  width: 100%;
  margin-bottom: 15px;
  height: 100%;
  max-height: 120px;
  object-fit: cover; /* Ensures the image covers the container */
  display: block; /* To prevent inline image spacing issues */
}

.card-category {
  font-size: 0.75rem;
}

.card-title {
  font-size: 0.90rem;
  font-weight: 500;
}

.card-para {
  font-size: 0.75rem;
}

.card-li-text {
  font-size: 0.75rem;
}

.card-ul {
  margin-top: auto;
  padding: 0.5rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.card-li {
    display: flex;
    gap: 0.75rem;
    align-items: center;
}