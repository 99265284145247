/* For mobile devices */
@media screen and (max-width: 768px) {
  .chat-app {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      "header header"
      "conversation conversation";
    overflow: hidden;
    height: 100vh;
    width: 100vw;
  }

  .chat-main {
    display: grid;
    grid-template-rows: 1fr min-content;
    height: -webkit-fill-available;
  }

  .chat-listing {
    padding: 1rem;
  }

  .chat-listing > div:first-child {
    margin-top: 20vh;
  }

  .chat-input {
    padding: 0.5rem;
  }

  .chat-input-con {
    margin: 0 auto;
    width: 95%;
  }

  .chat-input-con .input-area .submit-input {
    width: 2.5rem;
    height: 2.5rem;
    background-color: whitesmoke;
    border-radius: 2.5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-li-img {
    width: 20px;
    height: 20px;
  }
}
