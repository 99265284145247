@media only screen and (max-width: 900px) {


 

  .card-category {
    font-size: 14px;
    color: #978f8f;
    letter-spacing: 2px;
    margin-left: 15px;
  }

  .card-title {
    color: #000049;
    font-size: 1rem;
  }

  .card-para {
    margin-left: 15px;
    color: #6b6b7d;
    width: 235px;
    font-size: 12px;
  }

  .card-ul {
    padding: 0;
    margin-left: 15px;
  }

  .card-li {
    display: block;
    margin: 10px 0px;
  }

  .card-li-text {
    display: inline;
    position: relative;
    top: -6px;
    left: 5px;
    font-size: 14px;
  }

  .card-li-span {
    margin-bottom: 4px;
  }

  .card-button-div {
    display: flex;
    text-align: center;
    justify-content: space-between;
    margin: 10px 20px;
    margin-bottom: 5px;
  }

  .card-button-text {
    font-weight: 500;
    letter-spacing: 1px;
    color: #000049;
    font-size: 14px;
  }

  .card-button-img {
    filter: drop-shadow(0px 0px 10px #bfbfbf);
    width: 25px;
    background-color: white;
    border-radius: 303px;
    height: 25px;
    padding: 5px;
    position: relative;
    top: -5px;
  }
}
