/* For mobile devices */
@media screen and (max-width: 768px) {
  .app-header {
    position: fixed;
    top: 0px;
    background-color: white;
    border-bottom: 1px solid #eee;
  }

  .app-header .profile-button span {
    display: none;
  }

  .app-header .left .app-logo > svg {
    height: 27px; /* Fit the image to the height of the parent */
  }
}
