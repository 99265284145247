.your-prompt {
  /* margin-left: 100px; */
  padding: 1rem;
  /* background-color: #f1f1f1; */
  border-radius: 13px;
  padding-left: 15px;
  margin-top: 20px;
}

.search-prompt {
  /* padding: 0.75rem; */

  border-radius: 1rem;
  margin: 1rem 0;
  max-width: 100%;
  height: fit-content;
  width: fit-content;
  position: relative; /* For positioning the gradient overlay */
  overflow: hidden;
  padding: 0.5rem;
}

.search-prompt .content {
  display: grid;
  grid-template-columns: 35px auto;
  width: 100%;
  gap: 1rem;
}

.search-prompt-heading {
  padding-top: 5px;
  font-style: italic;
  border-radius: 1rem;
  /* background-color: #f1f1f1; */
}

.search-prompt .avatar img {
  width: 34px;
  border-radius: 34px;
  border: 1px solid #dedede;
  background-color: white;
  overflow: hidden;
  object-fit: cover;
  padding: 3px;
}

.search-prompt-heading ol {
  padding: 1rem;
}

.search-prompt[data-gradient="false"] {
  overflow: unset;
}


.search-prompt > * {
  position: relative; /* Ensure content is above the gradient */
  z-index: 2; /* Above the gradient */
}

.search-prompt .buttons {
  width: fit-content;
  display: flex;
  gap: 0.5rem;
}

.search-prompt .buttons button {
  background-color: transparent;
  padding: unset;
}
.search-prompt .buttons button svg {
  background-color: transparent;
  border-radius: 0.5rem;
  overflow: hidden;
  width: fit-content;
  padding: 0.5rem;
  color: rgb(138, 138, 138);

}
.search-prompt .buttons button:hover svg {
  background-color: #ececec;
  color: rgb(59, 59, 59);
}