/* For mobile devices */
@media screen and (max-width: 768px) {
  .conversation-widget {
    position: fixed;
    top: 50px;
    left: 0px;
    right: 0px;
    max-width: unset;
  }
}
