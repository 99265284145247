.chat-input-con {
 
}

.chat-input-con .input-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto .75rem auto;
  border-radius: 2.5rem;
  filter: drop-shadow(0px 0px 10px #bfbfbf);
  align-items: center;
  background-color: #ececec;
  height:  3.5rem;
  width: 100%;
  max-width: 600px;
  padding: 0 .75rem;
}

.chat-input-con .input-area input {
  width: 100%;
}

.chat-input-con .input-area .submit-input {
  width: 2.5rem;
  height:  2.5rem;
  background-color: whitesmoke;
  border-radius:  2.5rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

}


.card-li-img {
  width: 20px;
  height: 20px;
}