.dark-blue {
  background-color: #0074D9 !important;
  color: white !important;
}

.blue {
  background-color: #4096E3 !important;
  color: white !important;
}

.light-blue {
  background-color: #7FB8EA !important;
  color: white !important;
}

.chat-app {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header header"
    "conversation conversation";

  height: 100vh;
  overflow: hidden;
}

.app-bar {
  padding: 10px 10px;
  display: inline;
  cursor: pointer; /* Added to make it look clickable */
}

.chat-listing {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}


.chat-close-div {
  width: 25px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.chat-sidebar {
  background: #ececec;
  width: 90%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px;
  z-index: 10;
  padding-top: 30px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.chat-sidebar-0 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chat-sidebar-1 {
  overflow: auto;
}

.chat-sidebar.active {
  transform: translateX(0);
}

.chat-sidebar-1-span {
  font-size: 14px;
  margin: 0px 10px;
  font-weight: 500;
}

.chat-sidebar-button {
  padding: 0.5rem 1.25rem;
  /* margin: auto; */
  /* margin-bottom: 9px; */
  font-size: 1rem;
  color: #ffffff;
  border-radius: 10px;
  background-color: rgb(28, 158, 78);
  text-decoration: none;
  display: block;
}

.close-panel {
  padding: 0.5rem 1rem;
  margin-bottom: 5px;
  font-size: 1rem;
  color: red;
  border-radius: 10px;
  background-color: #f5f5f5; /* Light background for Material Design look */

  width: fit-content;
  /* Text overflow handling */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  border: none;
  cursor: pointer;
  display: block;
  text-decoration: none;
}

.new-conversation {
  padding: 0.5rem 1rem;
  margin-bottom: 5px;
  font-size: 1rem;
  color: #5f5f5f;
  border-radius: 10px;
  background-color: #f5f5f5; /* Light background for Material Design look */

  width: fit-content;
  /* Text overflow handling */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  border: none;
  cursor: pointer;
  display: block;
  text-decoration: none;
}

.new-conversation > span {
  margin-left: 1rem;
}

.chat-sidebar-button:hover {
  background-color: rgb(20, 110, 55);
}

.chat-sidebar-input {
  border: 1px solid #b3b3b3;
  width: 100%;
  font-size: 16px;
  padding: 10px 10px;
  outline: none;
  margin-left: -10px;
  border-radius: 10px;
}


.chat-con {
  height: -webkit-fill-available;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #dbdbdb #f5f5f5;
}

/* For Chrome, Edge, and Safari */
.chat-con::-webkit-scrollbar {
  width: 8px;
}

.chat-con::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.chat-con::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
  border: 2px solid #f1f1f1;
}

.chat-con::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


.chat-input {
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 16px;
  padding: 0px 0px;
  outline: none;
}

.chat-input-img {
  background-color: #ffff;
  padding: 5px 12px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  left: 10px;
}



.chat-main {
  grid-area: conversation;
  background-color: #ffff;
  color: black;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto min-content;
}

@media only screen and (min-width: 900px) {
  .app-bar {
    display: none; /* Hide the menu button on larger screens */
  }
  a {
    display: none;
  }
  .chat-close-div {
    display: none;
  }
  .chat-div {
    width: 100%;
  }

  .chat-main {
    width: auto;
    background-color: #ffff;
    color: black;
    overflow: hidden;

    display: grid;
    grid-template-rows: auto min-content;
  }

  .chat-con {
    height: auto;
    /* padding: 10px 60px; */
    gap: 1rem;
    display: grid;
    grid-template-rows: min-content;
  }

  .your-prompt {
    all: unset;
    display: flex;
    justify-content: right;
    margin-top: 20px;
  }

  .your-prompt div {
    background: #ececec;
    border-radius: 1rem;
    padding: 0.75rem 1.5rem;
  }

  .chat-input {
    width: 55%;
    height: 50px;
    background-color: #ececec;
    border: none;
    font-size: 1rem;
    padding-left: 20px;
    outline: none;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }

  .chat-input-img {
    background-color: #ffff;
    padding: 5px 12px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    left: 0px;
  }

  .close-panel {
    display: none;
  }
}

.quick-actions {
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 1rem;
  justify-content: end;
  font-size: 1rem;
  padding: 0 1rem 1rem 1rem;
}

.seeMore {
  padding: 0.5rem 1.5rem;
  background-color: #000049;
  color: white;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
  /* margin-left: auto; */
  width: fit-content;
  text-decoration: none;
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Initial shadow */
}

.seeMore:hover {
  background-color: #002a72; /* Slightly lighter color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Larger shadow on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

.notHappy {
  font-size: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  display: block;
  text-decoration: none;
  color: #000049;
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Default shadow */
  outline-offset: none;
  border: none;
}

.notHappy:hover, .notHappy[data-active='true']{
  background-color: #f1f1f1; /* Slightly darker background on hover */
  color: #000; /* Change text color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Larger shadow on hover */
  transform: translateY(-2px); /* Slight lift effect */
}
