.course-categories {
  transition: max-height 500ms ease, visibility 500ms ease;
  overflow: hidden;
  max-height: 0; /* Collapsed state */
  visibility: hidden;
 
}

.course-categories[data-visible="true"] { 
  padding: 1rem;
  visibility: visible;
  max-height: 500px; /* Adjust this to the maximum height of your content */
}

.course-categories .list {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
}
.course-categories .list .category {
  padding: 0.5rem 1.25rem; /* Adjust padding (top-bottom, left-right) */
  background-color: white;
  border-radius: 1rem;
  display: block;
  text-decoration: none;
  color: #000049;
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Default shadow */
}

.course-categories .list .category:hover {
  background-color: #f1f1f1; /* Slightly darker background on hover */
  color: #000; /* Change text color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Larger shadow on hover */
  transform: translateY(-2px); /* Slight lift effect */
}
