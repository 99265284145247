.overlay.transparent {
  background-color: transparent;
}

.conversation-widget {
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  min-width: 320px;
  max-width: 360px;
  width: 100%;
  max-height: 460px;
  height: 100%;
  min-height: fit-content;

  display: none;
  position: absolute;
  z-index: 130;
  right: 0;
  margin-top: 1rem;

  /* Material Design shadow */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.conversation-widget.visible {
  display: inline-table;
}

.conversation-widget .chat-sidebar-field button:first-child {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: unset;
  padding: unset;
  text-align: left;
  width: 100%;
  overflow: hidden;
}

.conversation-widget .chat-sidebar-field button:last-child {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: unset;
  display: none;
}

.conversation-widget .chat-sidebar-field:hover button:last-child {
  display: block;
  margin: unset;
  padding: unset;
}

.conversation-widget .chat-sidebar-field {
  display: grid;
  grid-template-columns: 92% auto;
  padding: 0.5rem;
  margin-bottom: 5px;
  font-size: 1rem;
  color: #5f5f5f;
  border-radius: 10px;
  background-color: transparent;

  /* Text overflow handling */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; /* Takes up the maximum width of the parent container */
  text-align: left;
  border: none;

  /* Transition for hover effect */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.conversation-widget .chat-sidebar-field:hover {
  background-color: whitesmoke; /* White background on hover */
}

.conversation-widget .chat-sidebar-field[data-active="true"] {
  background-color: #ffffff;
}