/* Apply skeleton effect only to .loadable elements with data-skeleton="true" */
.loadable {
    z-index: 0;
}

.loadable * {
    z-index: 2;
}

.loadable[data-skeleton="true"] {
  position: relative;
  overflow: hidden;
  background: #e0e0e0;
  border-radius: 1rem;
}

/* Add animated shimmer effect */
.loadable[data-skeleton="true"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #e0e0e0 25%, #f1f1f1 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 3s infinite; /* Increased duration for a slower effect */
}

/* Remove skeleton effect when data-skeleton="false" */
.loadable[data-skeleton="false"] {
  background: none;
}

.loadable[data-skeleton="false"]::before {
  display: none;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
