/* Basic CSS Normalize */

/* Set all elements to inherit the box-sizing model */
html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

/* Ensure a smooth rendering for text */
body {
  line-height: 1.5;
  font-family: Arial, Helvetica, sans-serif;
  color: #333;
  background-color: #fff;
}

/* Remove bullet points and padding from lists */
ul,
ol {
  list-style: none;
}

/* Remove default link styling */
a {
  text-decoration: none;
  color: inherit;
}

/* Normalize images and media elements */
img,
video,
iframe {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Normalize table elements */
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

/* Normalize form elements */
input,
textarea,
select,
button {
  font: inherit;
  border: none;
  outline: none;
  background: none;
}

button {
  cursor: pointer;
}

textarea {
  resize: vertical;
}

select {
  appearance: none;
}

/* Remove default styles from fieldsets and legends */
fieldset {
  border: none;
}

legend {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: bold;
}

/* Remove outline from focused elements while providing a focus style */
:focus {
  outline: 2px solid transparent; /* Customize for accessibility */
  outline-offset: 2px;
}
