/* * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
} */

.auth-container {
    align-items: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 200000;
}


.auth-container > div {
    position: relative;
    width: 500px;
    height: 500px;
    background-color: #ffffff;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.auth-container a {
    display: block;
}

.sub-title {
    font-size: 1rem;
    text-align: center;
}

.form-container {
    /* width: 50%; */
    width: 500px;
    height: 500px;
    padding: 2rem;
    background-color: #ffffff;
    transition: all 0.6s ease-in-out;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 10px;
}

.login-container {
    left: 0;
    z-index: 2;
}

.signup-container {
    left: 0;
    z-index: 2;
}

.container.signup-mode .login-container {
    left: -50%;
    opacity: 0;
    z-index: 1;
}

.container.signup-mode .signup-container {
    left: 0;
    opacity: 1;
    z-index: 2;
}

.title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
}

.input-field {
    position: relative;
    margin-bottom: 30px;
}

.input-field input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #333;
    border: none;
    border-bottom: 1px solid #ccc;
    background: none;
    outline: none;
}

.input-field label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    color: #aaa;
    pointer-events: none;
    transition: all 0.3s ease;
}

.input-field input:focus ~ label,
.input-field input:valid ~ label {
    top: -20px;
    font-size: 12px;
    color: #03a9f4;
}

.focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #03a9f4;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.input-field input:focus ~ .focus-border {
    transform: scaleX(1);
}

.btn {
    display: inline-block;
    width: 100%;
    padding: 10px;
    background-color: #03a9f4;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0288d1;
}

.text {
    margin-top: 20px;
    font-size: 14px;
    color: #777;
}

.text button {
    color: #03a9f4;
    text-decoration: none;
    font-weight: 600;
    background-color: unset;
    padding: 0;
    margin: 0;
}

.text button:hover {
    text-decoration: underline;
}

button.btn[disabled] {
    background-color: grey;
    cursor: unset;
}