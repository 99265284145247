.welcome-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}


.prompt-box {
    display: flex;  /* Align the prompts horizontally */
    gap: 10px;      /* Add space between the prompts */
}

.prompt {
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 150px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.prompt i {
    font-size: 24px;
    margin-bottom: 10px;
}

.prompt strong {
    display: block;  /* Make the title occupy full width */
    margin-bottom: 5px;  /* Add space between title and text */
}

.prompt p {
    margin: 1rem 0 0 0;
    font-size: 16px;
}

.prompt:hover {
    border-color: #4CAF50;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
}