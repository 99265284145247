/* Sidebar styles */
.sidebar {
  grid-area: sidebar;
  position: fixed;
  left: -100%;
  top: 0;
  min-width: 320px;
  width: 22vh;
  height: 100%;
  background-color: rgba(1, 100, 213, 0.75);
  color: white;
  transition: 0.3s ease;
  padding: 20px;
  z-index: 120;

  display: grid;
  grid-template-rows: min-content 1fr min-content;
  gap: 1rem;
  visibility: hidden;
}

/* TODO: Responsive sidebar width */
.sidebar.visible {
  left: 0;
  visibility: unset;
}

.sidebar .header {
  display: flex;
  justify-content: end; /* TODO: make it space-between if you put a logo */
}

.sidebar .header img {
  height: 40px;
  width: auto;
}

.sidebar .header button {
  padding: 1rem;
  height: 50px;
  border-radius: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.sidebar .left-menu {
  align-items: center;
  justify-content: center;
}

.sidebar .left-menu ul li {
  display: flex;
  justify-content: end;
}

.sidebar .left-menu a {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 2rem;
  width: fit-content;
}

.sidebar .left-menu a:hover {
  text-decoration: underline;
}

/* Button to toggle the sidebar */
button {
  background-color: #444;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

button:hover {
  background-color: #666;
}

.sidebar .social {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.sidebar .social > button {
  display: flex;
  align-items: center;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  background-color: white;
}
.sidebar .social > button svg {
  height: 1rem;
}

.manual-search {
  cursor: pointer;
  margin: 0.5rem auto;
  padding: 0.75rem 1rem; /* Increase padding for better touch target size */
  border: none;
  gap: 1rem;
  outline: none;
  display: flex;
  font-size: 1.2rem;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  background-color: white; /* Material Design primary color */
  color: black; /* Text color */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Material Design shadow */
  transition: box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth transition */
}

.manual-search:hover {
  background-color: whitesmoke; /* Darker shade on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Elevated shadow on hover */
}
