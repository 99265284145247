/* For mobile devices */
@media screen and (max-width: 768px) {
    .prompt-box {
        display: grid;  /* Align the prompts horizontally */
        gap: 10px;      /* Add space between the prompts */
        width: -webkit-fill-available;
        grid-template-columns: auto auto;
        justify-content: center;
    }
  }
  