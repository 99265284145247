/* General styles for the header */
.app-header {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  align-items: center;

  z-index: 100;
  padding: 0.5rem;
  height: min-content;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.app-header .left {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.app-header .left .app-logo {
  padding: 0;
  display: flex;
  border-radius: unset;
  max-width: 150px;
}
.app-header .left .app-logo:hover {
  background-color: unset;
}

.app-header .left .app-logo > svg, img {
  height: 35px; /* Fit the image to the height of the parent */
  width: min-content;
}

.app-header button,
.app-header a {
  border: none;
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  text-decoration: none;
  color: black;
  display: flex;
  cursor: pointer;
  position: relative;
  height: min-content;
}

.app-header button:hover,
.app-header a:hover {
  background-color: whitesmoke;
}

.app-header button svg {
  height: 1rem;
}

.app-header .profile {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

/* Profile area */
.profile-area {
  position: relative;
}

/* Profile icon with Material Design styling */
.app-header button.profile-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 35px;
  height: 35px;
  padding: unset;
}
.app-header button.profile-button span {
  padding: 0 1rem;
}

.app-header button > .profile-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  padding: unset;
}

.app-header button > .profile-icon > img {
  width: 100%;
}

/* Material Icons styling for the profile icon */
.material-icons {
  font-size: 36px;
  color: #666;
}

/* Dropdown menu for the profile */
.profile-menu {
  position: absolute;
  top: 45px;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: fit-content;
  max-width: 320px;
  z-index: 1;
}

.profile-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.profile-menu li {
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.profile-menu li:hover {
  background-color: #eee;
}

/* Login button with Material Design styles */
.login-button {
  padding: 10px 20px !important;
  white-space: nowrap;
}
